@keyframes bubble {
    0% {
      transform: scale(1);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
    50% {
      transform: scale(1.07); /* Grow the element */
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.3); /* Slight glow effect */
    }
    100% {
      transform: scale(1); /* Return to original size */
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
  }

  .shake-animation {
    animation: bubble 2s ease-in-out infinite;
  }